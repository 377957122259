<template>
	<div>
		<Header title='个人资料' rightTitle='保存' @preservation='preservation'></Header>
		<div @click="updateHeader" style="position: relative;text-align: center;margin-top: 30px;">
			<img :src="avatar==''?headerImage:host+avatar" style="width: 88px;height: 88px;border-radius: 50%;">
			<img src="../../assets/image/mine/xiangji.png" class="carmera">
		</div>
		<div class="infoWrap">
			<div class="infoChild">
				<div style="color: #2B2A25;font-size: 14px;font-weight: bold;">姓名</div>
				<div>
					<input v-model="username" type="text" placeholder="请输入" style="text-align: right;" />
				</div>
			</div>
			<div class="infoChild">
				<div style="color: #2B2A25;font-size: 14px;font-weight: bold;">性别</div>
				<div style="display: flex;align-items: center;">
					<div @click="check(item,index)" v-for="(item,index) in sexList" :key="index" class="checks">
						<img :src="selectSexIndex==index?seCircle:circle"
							style="width: 16px;height: 16px;margin-right: 2px;">
						<div style="color: #727273;font-size: 12px;">{{item}}</div>
					</div>
				</div>
			</div>
			<div class="infoChild">
				<div style="color: #2B2A25;font-size: 14px;font-weight: bold;">年龄</div>
				<div>
					<input v-model="age" type="text" placeholder="请输入" style="text-align: right;" />
				</div>
			</div>
			<div class="infoChild">
				<div style="color: #2B2A25;font-size: 14px;font-weight: bold;">板型</div>
				<div style="display: flex;align-items: center;">
					<div @click="checkBx(item,index)" v-for="(item,index) in PlateTypeList" :key="index" class="checks">
						<img :src="selectPlateTypeIndex==index?seCircle:circle"
							style="width: 16px;height: 16px;margin-right: 2px;">
						<div style="color: #727273;font-size: 12px;">{{item}}</div>
					</div>
				</div>
			</div>
			<div class="infoChild">
				<div style="color: #2B2A25;font-size: 14px;font-weight: bold;">所在城市</div>
				<div>
					<input v-model="city" type="text" placeholder="请输入" style="text-align: right;" />
				</div>
			</div>
			<div class="infoChild">
				<div style="color: #2B2A25;font-size: 14px;font-weight: bold;">电话</div>
				<div>
					<input :disabled="true" v-model="mobile" type="number" placeholder="请输入" style="text-align: right;" />
				</div>
			</div>
			<div style="margin-top: 20px;">
				<div style="color: #2B2A25;font-size: 14px;font-weight: bold;">个性签名</div>
				<textarea v-model="sign" rows="" cols="" placeholder="请输入个性签名" class="textStyle"></textarea>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		profile
	} from '../../api/mine.js'
	import {
		index
	} from '../../api/mine.js'
	import{
		host
	} from '../../utils/host.js'
	import Header from '@/components/header.vue'
	export default {
		name: "mineInfo",
		data() {
			return {
				host:host,
				headerImage:require('../../assets/image/mine/headerImg.png'),
				username: '',
				age: '',
				city: '',
				mobile: '',
				avatar: "",
				sign: '',
				selectPlateTypeIndex: 0,
				circle: require('../../assets/image/mine/circle.png'),
				seCircle: require('../../assets/image/mine/selectCircle.png'),
				selectSexIndex: 0,
				sexList: [
					'未知', '男', '女'
				],
				PlateTypeList: [
					'未知', '单板', '双板'
				],
				token: window.localStorage.getItem('token')
			}
		},
		components: {
			Header
		},
		created() {
			this.getInfo()
		},
		methods: {
			getInfo() {
				let data = new FormData()
				data.append('token', this.token)
				index(data).then(res => {
					console.log('我的个人信息', res)
					this.avatar = res.data.avatar
					this.username = res.data.username
					this.age = res.data.age
					this.city = res.data.city
					this.mobile = res.data.mobile
					this.sign = res.data.sign
					this.selectPlateTypeIndex = res.data.plate_type == null ? 0 : res.data.plate_type
					this.selectSexIndex = res.data.gender == null ? 0 : res.data.gender
				})
			},
			preservation() {
				let data = new FormData()
				data.append('token', this.token)
				data.append('username', this.username)
				data.append('age', this.age)
				data.append('sign', this.sign)
				data.append('mobile', this.mobile)
				data.append('city', this.city)
				data.append('plate_type', this.selectPlateTypeIndex)
				data.append('gender', this.selectSexIndex)
				data.append('avatar', this.avatar)
				profile(data).then(res => {
					console.log('保存个人信息', res)
					this.$toast('修改成功')
					this.getInfo()
				})
			},
			check(item, index) {
				this.selectSexIndex = index
			},
			checkBx(item, index) {
				this.selectPlateTypeIndex = index
			},
			updateHeader() {
				this.$router.push({
					path: '/updateHeader'
				})
			}
		}
	}
</script>

<style scoped>
	.carmera {
		width: 24px;
		height: 24px;
		position: absolute;
		bottom: 5%;
		right: 39%;
	}

	.infoWrap {
		margin: 38px 16px 0 16px;
		padding: 10px 14px 20px 14px;
		box-sizing: border-box;
		background: #FFFFFF;
		box-shadow: 0px 2px 12px 0px rgba(148, 148, 149, 0.5);
		border-radius: 8px;
	}

	.infoChild {
		width: 100%;
		height: 35px;
		margin-top: 10px;
		border-bottom: 1px solid #D8D8D8;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.checks {
		display: flex;
		align-items: center;
		margin-right: 12px;
	}

	.checks:last-child {
		margin: 0;
	}

	.textStyle {
		background: #F5F6F8;
		border-radius: 4px;
		height: 98px;
		width: 100%;
		outline: none;
		border: none;
		margin-top: 20px;
		padding: 14px;
		box-sizing: border-box;
		font-size: 12px;
		color: #2B2A25;
	}
</style>
