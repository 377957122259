<template>
	<div class="top" style="display: flex;align-items: center;justify-content: space-between;">
		<div @click="back">
			<img src="../assets/image/login/back.png" style="width: 11px;height: 18px;">
		</div>
		<div style="color: #434444;font-size: 16px;font-weight: bold;">{{title}}</div>
		<div class="rightTitleStyle" @click="goUrl">{{rightTitle}}</div>
	</div>
</template>

<script>
	export default {
		name: '',
		data() {
			return {

			}
		},
		props: {
			// title: {
			// 	type: Array,
			// 	default: () => {
			// 		return []
			// 	}
			// },
			title: {
				type: String,
				default: ''
			},
			rightTitle: {
				type: String,
				default: ''
			}
		},
		methods: {
			back() {
				this.$router.go(-1)
			},
			goUrl(){
				this.$emit('preservation')
				// this.$router.push({
				// 	path:this.rightTitle=='用户登录'?'/login':'/register',
				// 	query: {
				// 		rightTitle: this.rightTitle=='用户登录'?'用户注册':'用户登录'
				// 	}
				// })
			}
		}
	}
</script>

<style scoped>
	.top {
		margin: 0.2rem 0.5rem 0 0.5rem;
	}

	.rightTitleStyle {
		color: #003CE5;
		font-size: 14px;
	}
</style>
