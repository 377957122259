import request from "@/utils/request"
// 登录
export function index(data) {
	return request({
		url: '/api/User/index',
		method: 'post',
		data
	})
}
// 获取领奖地址
export function userAddress(data) {
	return request({
		url: '/api/User/userAddress',
		method: 'post',
		data
	})
}
// 添加领奖地址
export function addAddress(data) {
	return request({
		url: '/api/User/addAddress',
		method: 'post',
		data
	})
}
// 编辑个人中心
export function profile(data) {
	return request({
		url: '/api/User/profile',
		method: 'post',
		data
	})
}
